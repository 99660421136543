import SearchBox from "./SearchBox";
const NewNavbar = () => {
    return(
        <nav className="w-full h-[55px] bg-gray-800 justify-center items-center inline-flex flex-wrap">
            <div className="w-full h-[55px] justify-center items-center inline-flex">
                <div className="p-5 justify-start items-start gap-2.5 flex">
                    <div className="text-white text-xs font-medium">Home</div>
                </div>
                <div className="p-5 justify-start items-start gap-2.5 flex">
                    <div className="text-white text-xs font-medium">Designs</div>
                </div>
                <div className="p-5 justify-start items-start gap-2.5 flex">
                    <div className="text-white text-xs font-medium">About Me</div>
                </div>
                <div className="p-5 justify-start items-start gap-2.5 flex">
                    <div className="text-white text-xs font-medium">Case Studies</div>
                </div>
            </div>
        </nav>
    );

}

export default NewNavbar;