import logo from './logo.svg';
import './App.css';


import React, {useEffect, useState} from 'react';
import Footer from '../src/components/Footer';
import Home from './components/Home.jsx';
import NewNavbar from './components/NewNavbar.jsx';
import MyButton from "./components/MyButton";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Search from './components/Search.js';
import CustomAnimation from './components/CustomAnimation.jsx';


function App() {
    const imageSizeStyle = {
        width: '25%', // Set the width to 50%
        height: 'auto', // Maintain aspect ratio
    };
  return (
      <Router >
          <div className="App">
            <NewNavbar/>
            <CustomAnimation />
            {/*<Home /> remove coments when content is ready*/} 
            <div>
            
            </div>
              
            
            
            </div>
      </Router>
  );
}

export default App;
