import React, { useEffect } from 'react';
import lottie from 'lottie-web'; // Import the lottie library
import loadingData from '../images/loading.json'
import '../css/animation.css';

function CustomAnimation() {
    const animationContainerRef = React.useRef(null);

    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: animationContainerRef.current,
            renderer: 'svg', // Use 'svg' or 'canvas' renderer based on your preference
            loop: true, // Set to true if you want the animation to loop
            autoplay: true, // Set to true if you want the animation to play when the component mounts
            animationData: loadingData, // Pass your animation data here
        });

        return () => {
            // Cleanup when the component unmounts
            animation.destroy();
        };
    }, []);

    return (
               <div className='flex flex-col items-center justify-center'> 
            <div className='Construction-Animation w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6' ref={animationContainerRef}></div>
            <p className='text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-3xl'>Portfolio down for Maintenance</p>
            </div>
    );  
}

export default CustomAnimation;
