import React, { useEffect } from 'react';
import '../css/footer.css';
import { BsDribbble, BsLinkedin, BsInstagram } from "react-icons/bs";

function Footer() {


    return (

        <div className="footer-container">
            <div className="content">
                <div className="links-container">
                     <span>Find me in:</span>

                        <span className="icon-container">
                            <a href="https://www.linkedin.com/in/dpsacharya" target="_blank" rel="footer">
                                <BsLinkedin className='inline-block'/>
                            </a>
                        </span>
                        <span className="icon-container">
                            <a href="https://dribbble.com/dps-acharya" target="_blank" rel="footer">
                                <BsDribbble className='inline-block'/>
                            </a>
                        </span>
                        <span className="icon-container">
                            <a href="https://www.instagram.com/dps.acharya" target="_blank" rel="footer">
                                <BsInstagram className='inline-block'/>
                            </a>
                        </span>

                 </div>
                <div>Made with ❤ & ⚛ </div>
             </div>

        </div>

    );
}

export default Footer;
