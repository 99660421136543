import React from 'react';
import '../css/home.css';
import CustomAnimation from "./CustomAnimation";
import heroImage from "../images/hero.png";
import DesignList from "../designs.json"
import child from '../images/child.jpg'

const HomeCards = ({design}) => {
    return(
        
        <div className="w-[289px] h-[210px] shadow flex-col justify-start items-start inline-flex">
        <div className="self-stretch h-[148px] bg-white rounded-tl-[15px] rounded-tr-[15px] justify-center items-center gap-2.5 inline-flex">
            <img className="grow shrink basis-0 self-stretch" src={child} />
        </div>
        <div className="self-stretch h-[62px] px-4 py-2.5 bg-white flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch h-[19px] flex-col justify-start items-start gap-2.5 flex">
                <div className="self-stretch text-black text-opacity-90 text-base font-bold font-['Inter']">{design.title}</div>
            </div>
            <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-black text-opacity-90 text-xs font-medium font-['Inter']">{design.company}</div>
                <div className="text-right text-neutral-400 text-xs font-medium font-['Inter']">{design.date} </div>
            </div>
        </div>
    </div>
        

    );
}

function Home(){

    return(
        <div className="main-container">
        {/*<div className='hero-section' >
            <h1 className='floating-text text-4xl font-bold'>Designing Reality</h1>
            <img src={heroImage} alt='design to reality' className='hero-image'/>
        </div>*/}

            <div className='max-w-screen-xl mx-auto'>
                <div>
                    <h1 className='text-3xl font-semibold'>Design Projects</h1>
                </div>

                <div className='flex flex-wrap w-full px-6 gap-10'>
                { DesignList.map(design =>(
                    <HomeCards key={design.id} design={design} />
                    ))
                }
                </div>
        </div>
        </div>
                
    );

}
export default Home;